import axios from 'axios'
import store from '../store'
// import router from '../router'
import {
    Toast
} from 'vant';
// 创建 instance 请求对象
const instance = axios.create({
    baseURL: '', // 接口地址
    withCredentials: false, // 跨域请求时是否需要使用凭证
    timeout: 30000,
    validateStatus: function () {
        // 使用async-await，处理reject情况较为繁琐，所以全部返回resolve，在业务代码中处理异常
        return true
    }
})
axios.defaults.crossDomain = true
// 请求拦截器
instance.interceptors.request.use((config) => {
    // if (store.state.authorization) { // 判断是否存在token，如果存在的话，则每个http header都加上token
    //     config.headers.authorization = store.state.authorization;
    // }
    return config
}, (error) => {
    // 错误抛到业务代码
    error.data = {}
    error.data.msg = '服务器异常，请联系管理员！'
    return Promise.resolve(error)
})
// 错误提示
const showStatus = (status) => {
    let message = ''
    // 这一坨代码可以使用策略模式进行优化
    switch (status) {
        case 602:
            message = '登录失败'
            break
        case 603:
            message = '登录过期'
            break
        case 604:
            message = '登录过期'
            break
        case 607:
            message = '用户已注销'
            break
        case 608:
            message = '密码校验失败，请输入正确密码'
            break
        case 610:
            message = '手机号与验证码不匹配'
            break
        case 611:
            message = "用户不存在或被禁用"
            break
        default:
            message = `连接出错(${status})!`
    }
    return `${message}`
}
// 响应拦截器
instance.interceptors.response.use((response) => {
    const status = response.status
    let msg = ''
    if (status < 200 || status >= 300) {
        // 处理http错误，抛到业务代码
        msg = showStatus(status)
        if (typeof response.data === 'string') {
            response.data = {
                msg
            }
        } else {
            response.data.msg = msg
        }
        // 错误信息提示
        Toast.clear()
        Toast.fail(msg);
        // 登录过去跳转到登录
        if (status === 603 || status === 604) {
            store.state.authorization = ''
            // router.push({
            //     path: '/login'
            // })
        }
    }
    return response
}, (error) => {
    // 错误抛到业务代码
    error.data = {}
    error.data.msg = '请求超时或服务器异常，请检查网络或联系管理员！'
    return Promise.resolve(error)
})
export default instance