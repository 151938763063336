import request from '@/utils/request'
import qs from 'qs'
// 获取验证码
export const code = params => {
    return request({
        method: 'POST',
        url: '/graphicalH5Public/mobile/code',
        data: qs.stringify(params)
    })
}
// 登录
export const phoneLogin = params => {
    return request({
        method: 'POST',
        url: '/graphicalH5Public/user/phoneLogin',
        data: qs.stringify(params)
    })
}
// 完善信息
export const userInfoChange = params => {
    return request({
        method: 'POST',
        url: '/graphicalH5Public/user/userInfoChange',
        data: qs.stringify(params)
    })
}

// 会员之家url
export const homeUrl = params => {
    return request({
        method: 'POST',
        url: '/graphicalH5Public/xiaoe/findXeUrl',
        data: qs.stringify(params)
    })
}