import Vue from 'vue'
import Vuex from 'vuex'
// 数据持久化
import persistedState from 'vuex-persistedstate'
// 用户信息模块
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        userInfo: {}, //用户信息
        authorization: null, // token
        firstLogin: false,
        fromReportType: 1,
        reportType: 1,
        xeUrl: ''
    },
    mutations: {
        SET_USERINFO: (state, data) => {
            state.userInfo = data
        },
        SET_AUTHORIZATION: (state, data) => {
            state.authorization = data
        },
        SET_FIRSTLOGIN: (state, data) => {
            state.firstLogin = data
        },
        SET_FROMREPORTTYPE: (state, data) => {
            state.fromReportType = data
        },
        SET_REPORTTYP: (state, data) => {
            state.reportType = data
        },
        SET_XEURL: (state, data) =>{
            state.xeUrl = data
        }
    },
    plugins: [persistedState()]
})