import Vue from 'vue'
// import 'vant/lib/index.css'
import {
    Button,
    Field,
    Form,
    Toast,
    Dialog,
    CellGroup,
    Icon,
    Swipe,
    SwipeItem,
    Tab,
    Tabs,
    List,
    Cell,
    ActionSheet,
    Calendar
} from 'vant'
Vue.use(Button)
    .use(Form)
    .use(Field)
    .use(Toast)
    .use(Dialog)
    .use(CellGroup)
    .use(Icon)
    .use(Swipe)
    .use(SwipeItem)
    .use(Tab)
    .use(Tabs)
    .use(List)
    .use(Cell)
    .use(ActionSheet)
    .use(Calendar);