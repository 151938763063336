<template>
  <div class="open-vip-wrap flex flex-center-center">
    <div class="open-vip-modal">
      <div class="title">完善信息</div>
      <div class="sub-title">方便及时了解最新的特色数据更新情况</div>
      <van-cell-group>
        <van-field label-width="64px" v-model="company" type="text" label="公司名称" clearable />
        <!-- 输入手机号，调起手机号键盘 -->
        <van-field label-width="64px" v-model="email" type="text" label="邮箱账号" clearable />
        <!-- 允许输入正整数，调起纯数字键盘 -->
        <van-field label-width="64px" v-model="userName" type="text" label="姓名" clearable />
      </van-cell-group>
      <van-button
        class="submit-btn"
        type="primary"
        @click="handleSubmit"
        size="small"
        color="#ea6a44"
      >提交</van-button>
      <van-icon name="close" color="#fff" @click="closeBtnHandle" size="28px" class="close" />
    </div>
  </div>
</template>

<script>
import { userInfoChange } from "@/api/login";
export default {
  data() {
    return {
      company: "",
      email: "",
      userName: ""
    };
  },
  methods: {
    closeBtnHandle() {
      // 关闭浮层
      this.$emit("removeModal");
    },
    async handleSubmit() {
      this.$toast({
        type: "loading",
        duration: 0
      });
      let { data } = await userInfoChange({
        company: this.company,
        email: this.email,
        userName: this.userName
      });
      this.$toast.success(data.data);
      this.$emit("removeModal");
    }
  }
};
</script>

<style lang="less" scoped>
.open-vip-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.open-vip-modal {
  position: relative;
  width: 260px;
  height: 280px;
  background: #fff;
  border-radius: 8px;
  padding: 20px 10px;
  box-sizing: border-box;
  .title {
    font-size: 18px;
    margin-bottom: 14px;
  }
  .sub-title {
    font-size: 12px;
    color: #979797;
    margin-bottom: 10px;
  }
  .submit-btn {
    padding: 4px 40px;
  }
  .close {
    position: absolute;
    bottom: -60px;
    z-index: 9999;
    left: 50%;
    transform: translateX(-14px);
  }
  /deep/ .van-field__label{
    font-size: 12px;
    font-weight: 600;
    color: #434343;
  }
}
</style>
