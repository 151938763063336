<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <fill-info @removeModal="onRemoveVipModal" v-if="showDialog"></fill-info>
    <!-- <Tabbar v-if="$route.meta.isTabbarRoute" :model="model" :tabbar="bar" /> -->
  </div>
</template>

<script>
import FillInfo from "@/components/fillInfo";
// import Tabbar from "@/components/Tabbar";
import { mapState, mapMutations } from "vuex";

export default {
  name: "App",
  components: {
    FillInfo,
    // Tabbar
  },
  created() {
    let REPORTLINK = window.location.href.split("#")[1];
    //  非研报页面  默认宏观
    if (REPORTLINK.indexOf("report/1") !== -1) {
      this.SET_REPORTTYP(1);
    } else if (REPORTLINK.indexOf("report/2") !== -1) {
      this.SET_REPORTTYP(2);
    }
    // 钢铁
    if (this.reportType == 2) {
      this.bar = this.tabbar1;
    } else {
      // 行业
      this.bar = this.tabbar;
    }
    this.model = this.reportType
  },
  mounted() {},
  data() {
    return {
      showDialog: false,
      tabbar: [
        {
          iconImg: require("./static/img/nav_icon/nav_icon_report_normal.png"),
          iconActiveImg: require("./static/img/nav_icon/nav_icon_report_Selected.png"),
          text: "研报",
          link: 'report/1'
        },
        {
          iconImg: require("./static/img/nav_icon/nav_icon_news_normal.png"),
          iconActiveImg: require("./static/img/nav_icon/nav_icon_news_Selected.png"),
          text: "NSG资讯",
          link: "/news"
        },
        {
          iconImg: require("./static/img/nav_icon/nav_icon_vip_normal.png"),
          iconActiveImg: require("./static/img/nav_icon/nav_icon_vip_Selected.png"),
          text: "会员之家",
          link: null
        },
        {
          iconImg: require("./static/img/nav_icon/nav_icon_pc_normal.png"),
          iconActiveImg: require("./static/img/nav_icon/nav_icon_pc_Selected.png"),
          text: "PC端",
          link: '/light'
        },
        {
          iconImg: require("./static/img/nav_icon/nav_icon_routine_normal.png"),
          iconActiveImg: require("./static/img/nav_icon/nav_icon_routine_Selected.png"),
          text: "小程序",
          link: null
        }
      ],
      tabbar1: [
        {
          iconImg: require("./static/img/nav_icon/nav_icon_report_normal.png"),
          iconActiveImg: require("./static/img/nav_icon/nav_icon_report_Selected.png"),
          text: "研报",
          link: 'report/2'
        },
        {
          iconImg: require("./static/img/nav_icon/nav_icon_vip_normal.png"),
          iconActiveImg: require("./static/img/nav_icon/nav_icon_vip_Selected.png"),
          text: "会员之家",
          link: null
        },
        {
          iconImg: require("./static/img/nav_icon/nav_icon_pc_normal.png"),
          iconActiveImg: require("./static/img/nav_icon/nav_icon_pc_Selected.png"),
          text: "PC端",
          link: '/industry'
        },
        {
          iconImg: require("./static/img/nav_icon/nav_icon_routine_normal.png"),
          iconActiveImg: require("./static/img/nav_icon/nav_icon_routine_Selected.png"),
          text: "小程序",
          link: null
        }
      ],
      bar: [],
      model: this.reportType
    };
  },
  watch: {
    "$store.state.firstLogin": {
      handler(val) {
        this.showDialog = val;
      }
    }
  },
  computed: {
    ...mapState(["firstLogin", "reportType"])
  },
  methods: {
    ...mapMutations(["SET_FIRSTLOGIN", "SET_REPORTTYP"]),
    onRemoveVipModal() {
      this.showDialog = false;
      this.SET_FIRSTLOGIN(false);
    },
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}
</style>
