import Vue from 'vue'
import Router from 'vue-router'
// 路由重复push
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)
const routes = [{
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录',
        },
        component: () => import( /* webpackChunkName: "login" */ '@/views/login/index.vue'),
    },
    {
        path: '/report/:type',
        name: 'report',
        meta: {
            title: '研报',
            requiresAuth: true,
            keepAlive: true,
            isTabbarRoute: true
        },
        component: () => import( /* webpackChunkName: "report" */ '@/views/report/index.vue'),
    },
    {
        path: '/report/detail/:id/:type',
        name: 'reportDetail',
        meta: {
            title: '研报详情',
            requiresAuth: true,
        },
        component: () => import( /* webpackChunkName: "reportDetail" */ '@/views/report/detail.vue'),
    },
    {
        path: '/trend',
        name: 'trend',
        meta: {
            title: '大势研判',
            requiresAuth: true,
            keepAlive: true
        },
        component: () => import( /* webpackChunkName: "trend" */ '@/views/report/trend.vue'),
    },
    {
        path: '/meeting',
        name: 'meeting',
        meta: {
            title: '会议通知',
            requiresAuth: true,
            keepAlive: true
        },
        component: () => import( /* webpackChunkName: "meeting" */ '@/views/meeting/index.vue'),
    },
    {
        path: '/forum',
        name: 'forum',
        meta: {
            title: '博智论坛',
            requiresAuth: true,
            keepAlive: true
        },
        component: () => import( /* webpackChunkName: "forum" */ '@/views/meeting/forum.vue'),
    },
    {
        path: '/enterance/:type',
        name: 'enterance',
        meta: {
            title: '入门会客厅',
            requiresAuth: true,
            keepAlive: true
        },
        component: () => import( /* webpackChunkName: "enterance" */ '@/views/enterance/index.vue'),
    },
    {
        path: '/news',
        name: 'news',
        meta: {
            title: '新闻首页',
            requiresAuth: true,
            keepAlive: true,
            isTabbarRoute: true
        },
        component: () => import( /* webpackChunkName: "news" */ '@/views/news/index.vue'),
    },
    {
        path: '/news/list',
        name: 'newsList',
        meta: {
            title: '新闻列表',
            requiresAuth: true,
        },
        component: () => import( /* webpackChunkName: "newsList" */ '@/views/news/newsList.vue'),
    },
    {
        path: '/light',
        name: 'light',
        meta: {
            title: '轻量数据库简介',
            requiresAuth: true,
            isTabbarRoute: true
        },
        component: () => import( /* webpackChunkName: "light" */ '@/views/enterance/lightGuide.vue'),
    },
    {
        path: '/industry',
        name: 'industry',
        meta: {
            title: '行业数据库简介',
            requiresAuth: true,
            isTabbarRoute: true
        },
        component: () => import( /* webpackChunkName: "industry" */ '@/views/enterance/industryGuide.vue'),
    },
    {
        path: '/user',
        name: 'user',
        meta: {
            title: '',
            requiresAuth: false,
            isTabbarRoute: false
        },
        component: () => import( /* webpackChunkName: "user" */ '@/views/userPermission.vue'),
    },
    {
        path: '*',
        redirect: '/login'
    }
]
const router = new Router({
    routes
})

export default router